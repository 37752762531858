@media only screen and (min-height: 400px) {
  .App {
    font-family: sans-serif;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .content {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
    flex-grow: 2;
  }
}

@media only screen and (max-height: 400px) {
  .App {
    font-family: sans-serif;
    text-align: center;
    width: 100%;
    height: 350px;
    max-height: 350px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .content {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
    height: 350px;
    max-height: 350px;
    font-size: 10px;
  }

  img {
    max-height: 125px !important;
    object-fit: object-fit;
  }

  header {
    display: none !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  height: 100vh;
  /*
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  */
}

#root {
  background-image: url("./img/coworking.jpg");
  background-size: cover;
}

.section-enter {
  transition-duration: 1000ms;
  transform: scale(0.7) rotate(-10deg);
  opacity: 0.5;
}

.section-exit {
  transition-duration: 1000ms;
  transform: scale(1);
  opacity: 1.0;
}

.test > div {
}

header,
footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

#logo {
  justify-content: flex-start;
}

a:link.headerlink {
  color: lightgray;
}

a:visited.headerlink {
  color: lightgray;
}

.ctax {
  background-color: #db1212;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: 12px;
  outline: none;
  border-color: white;
  text-decoration: none;
}

a:link.cta {
  color:white;
}

a:visited.cta {
  color: white;
}

.headerlinks {
}

#menue {
  justify-content: flex-end;
  border: 1px solid;
}

.content {
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quote {
  display: flex;
  border: 1px solid;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  background-color: rgba(238, 228, 228, 0.7);
  margin: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 25px;
}

/*
.section-enter-active {
  transition-duration: 1500ms;
  transform: scale(0.4) rotate(-45deg);
}

.section-exit {
  transform: scale(0.8) rotate(-45deg);
  transition: transform 750ms;
}
.section-exit-active {
  transform: scale(0.8);
  transition: transform 750ms;
}*/

/*
img {
  width: 50%;
  object-fit: scale-down;
}
*/

.withmargin {
  margin: 20px !important;
}

.test {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.realcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.imagewrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.imagewrapper > img {
  object-fit: scale-down;
  max-width: 40vw;
  max-height: 40vh;
}

.animated {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 2s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: grow;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: -moz-transform;
  transition-duration: 2s;
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.8);
  }
  68% {
    transform: scale(0.7);
  }
  72% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
